import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const LawnFertilizing = () => {

	const TheTitle = "Lawn Fertilizing Services"
	const TheDescription = "Fertilizing can be essential to building a vibrant green and well manicured lawn. Rain and mowing strip away the nutrients from your lawn which need to be replaced. Fertilizing can be very daunting with many different blends of different nutrients and technologies. To get the best effects the right nutrient blend needs to be used."
	const TheCanonical = "https://gardenescape.ca/services/lawn-fertilizing/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/bush.png"
					alt="background placeholder"
					layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>

			<div className="mainWrapper my-16">
			<h2>WHAT’S FERTILIZING FOR?</h2>
			<p>Fertilizing can be essential to building a vibrant green and well manicured lawn. Rain and mowing strip away the nutrients from your lawn which need to be replaced. Fertilizing can be very daunting with many different blends of different nutrients and technologies. To get the best effects the right nutrient blend needs to be used.</p>
			<p>When fertilized correctly your lawn will grow, strong, greener and survive drought and weather the winter better.</p>
			<h2>HOW ELSE CAN FERTILIZING WORK FOR ME?</h2>
			<p>Not just your lawn needs fertilizer, garden beds can be greatly enhanced with the proper application of a properly balanced fertilizer.</p>
			<h2>HOW DO I PROGRESS?</h2>
			<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>
			<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
			<p>We are happy to help you with all things related to beautifying your property, some other major services we offer are; Regular lawn mow, fertilizing, Seasonal Yard cleanup, Hedge and Shrub pruning, Aeration and Power Raking, Power washing and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default LawnFertilizing